import styled, { DefaultTheme, css } from 'styled-components';
import { OfferStatus } from 'views/Vendor/types';
import { ReactComponent as House } from '../../assets/icons/house.svg';

const iconStyles = css`
  fill: ${(props) => props.theme.hub.colorPalette.misc.green};
  width: 50px;
  height: 50px;
`;

export const CardWrapper = styled.div<{ isSingleCard?: boolean }>`
  display: grid;
  grid-template-columns: ${(props) => (props.isSingleCard ? '1fr' : '1fr 1fr')};
  grid-gap: 20px;

  @media screen and (${(props) => props.theme.media.widthLtSml}) {
    grid-template-columns: 1fr;
  }

  @media screen and (${(props) => props.theme.media.widthMed}) {
    grid-template-columns: ${(props) =>
      props.isSingleCard ? '1fr' : '1fr 1fr 1fr'};
  }
`;

export const EmptyOffersMessage = styled.p`
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeight};
  font-size: ${(props) => props.theme.typography.xsmall};
  padding: 0;
  position: relative;
  width: 100%;
  display: block;
`;

export const ApplicantOffer = styled.div`
  background: ${(props) => props.theme.colorPalette.branding.white};
  border: 1px solid ${(props) => props.theme.colorPalette.forms.inputBorder};
`;

export const OfferListHeader = styled.h4`
  padding: 15px 10px 0;
  font-family: ${(props) => props.theme.fonts.primary.font};
  font-size: ${(props) => props.theme.typography.large};
  margin: 0;
  border-top: 1px solid ${(props) => props.theme.colorPalette.forms.inputBorder};

  @media screen and (${(props) => props.theme.media.widthMed}) {
    padding: 25px 20px 0;
  }
`;

export const OfferList = styled.div`
  padding: 10px 10px 0;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  font-family: ${(props) => props.theme.fonts.primary.font};

  > p {
    border-bottom: 1px solid
      ${(props) => props.theme.colorPalette.forms.inputBorder};
    height: 100%;
    align-items: center;
    display: flex;
    width: 100%;

    &:nth-last-child(-n + 3) {
      border: 0;
    }
  }

  @media screen and (${(props) => props.theme.media.widthMed}) {
    padding: 20px 20px 10px;
  }
`;

export const getStatusColor = (theme: DefaultTheme, status: OfferStatus) => {
  switch (status) {
    case OfferStatus.Rejected:
      return theme.hub.colorPalette.primary.red;
    case OfferStatus.Accepted:
      return theme.hub.colorPalette.misc.green;
    case OfferStatus.Withdrawn:
      return theme.hub.colorPalette.primary.grey;
    case OfferStatus.Pending:
      return theme.hub.colorPalette.misc.darkGrey;
    default:
      return theme.colorPalette.branding.ink;
  }
};

export const OfferPrice = styled.span<{
  status: OfferStatus;
  isActive: boolean;
}>`
  color: ${(props) => getStatusColor(props.theme, props.status)};
  font-weight: ${(props) => props.theme.fonts.primary.fontWeight};
  font-size: ${(props) => props.theme.typography.large};
  position: relative;

  @media screen and (${(props) => props.theme.media.widthMed}) {
    font-size: ${(props) => props.theme.typography.xlarge};
  }

  &:after {
    content: '';
    width: 100%;
    display: block;
    height: 1px;
    background: ${(props) => getStatusColor(props.theme, props.status)};
    bottom: 12px;
    position: absolute;

    ${(props) =>
      props.isActive &&
      `display: none;
    `}
  }
`;

export const OfferDate = styled.span`
  font-weight: ${(props) => props.theme.fonts.primary.fontWeight};
  font-size: ${(props) => props.theme.typography.small};
  margin-left: 10px;

  @media screen and (${(props) => props.theme.media.widthMed}) {
    font-size: ${(props) => props.theme.typography.default};
    margin-left: 50px;
  }
`;

export const OfferStatusTag = styled.span<{ status: OfferStatus }>`
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeightMed};
  font-size: ${(props) => props.theme.typography.xxsmall};
  text-transform: uppercase;
  margin-left: auto;
  background-color: ${(props) => getStatusColor(props.theme, props.status)};
  padding: 5px 10px;
  border-radius: 2px;
  color: ${(props) => props.theme.colorPalette.branding.white};
  text-align: center;

  @media screen and (${(props) => props.theme.media.widthMed}) {
    font-size: ${(props) => props.theme.typography.xsmall};
    margin-left: 50px;
  }
`;

export const AcceptedOfferCard = styled.div`
  background: ${(props) => props.theme.colorPalette.branding.white};
  padding: 20px;
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeight};
`;

export const AcceptedTitle = styled.h3`
  color: ${(props) => props.theme.hub.colorPalette.misc.green};
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeight};
`;

export const AcceptedParagraph = styled.p<{ showLink: boolean }>`
  margin: 10px 0 30px;
  span {
    font-weight: ${(props) => props.theme.fonts.secondary.fontWeightMed};
    ${(props) => !props.showLink && `display: none;`}
  }
`;

export const HouseIcon = styled(House)`
  ${iconStyles}
  border: 1px solid ${(props) => props.theme.colorPalette.forms.disabled};
  padding: 12px;
`;
