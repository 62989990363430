export type MalformedJSONError = {
  message: 'Malformed JSON';
  s3Path: string;
  json?: string;
};

export enum FlagCookies {
  employee = 'employee',
}

export enum FeatureFlags {
  APPLICANT = 'applicant-hub',
  LETTINGS = 'landlord-hub',
  UPLOAD = 'document-upload',
  MARKETING_STATS = 'marketing-stats',
  FEEDBACK = 'viewing-feedback',
  APPLICANT_RECORD = 'applicant-record',
  MAINTENANCE_MODE = 'maintenance-mode',
  REAPIT_MAINTENANCE_MODE = 'reapit-maintenance-mode',
  VIEWINGS = 'applicant-viewings',
  APPLICANT_OFFERS = 'applicant-offers',
  LETTING_FEEDBACK = 'letting-feedback',
}
